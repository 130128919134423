/* global pep_global_config */
// automatically load Spektrix component scripts based on components on the page
// and attach our API FormHandler for newsletter signup forms
import { initialiseForms } from '../module/form';

export default function initSpektrix() {
    const components_on_page = [];
    let api_handlers_on_page;

    // If a Spektrix Iframe is embedded on an arbitrary page, we must include the resize script.
    const clientUrl = pep_global_config?.embedded_purchase_path_base_url ?? null;

    if (clientUrl) {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
                document.head.appendChild(script);
            });
        };

        const initializeIframes = async() => {
            try {
                const iFrameScripts = [...document.querySelectorAll('iframe[src$="resize=true"]')];
                const cachedFrames = [];

                if (iFrameScripts.length > 0) {
                    for (const frame of iFrameScripts) {
                        const placeholder = document.createComment('iframe-placeholder');
                        frame.parentNode.insertBefore(placeholder, frame.nextSibling);

                        // Cache the frames and reload them after the integrate script
                        cachedFrames.push({ frame, placeholder });
                    }

                    // Await ensures the integrate script is ready before we remove and re-attach.
                    await loadScript(`${clientUrl}/scripts/integrate.js`);

                    for (const { frame, placeholder } of cachedFrames) {
                        frame.remove();
                        frame?.setAttribute('id', 'SpektrixIFrame');

                        placeholder?.parentNode?.insertBefore(frame, placeholder.nextSibling);
                        placeholder?.remove();
                    }
                }
            } catch (error) {
                // Spektrix spits out console logs anyway so we might as well
                // eslint-disable-next-line no-console
                console.log(error);
            }
        };

        // trigger the above functionality in a predictable order.
        initializeIframes();
    }

    if (!api_handlers_on_page) {
        api_handlers_on_page = initialiseForms('[data-api-type="spektrix"]');
    }

    [
        'spektrix-login-status',
        'spektrix-basket-summary',
        'spektrix-donate',
        'spektrix-gift-vouchers',
        'spektrix-memberships',
        'spektrix-merchandise'
    ].forEach(component => {
        if (document.querySelector(component)) {
            components_on_page.push(component);
        }
    });

    if (components_on_page.length > 0) {
        const loader = document.createElement('script');
        loader.setAttribute('src', 'https://webcomponents.spektrix.com/stable/webcomponents-loader.js');
        document.head.appendChild(loader);

        const component = document.createElement('script');
        component.setAttribute('src', 'https://webcomponents.spektrix.com/stable/spektrix-component-loader.js');
        component.setAttribute('data-components', components_on_page.join(','));
        document.head.appendChild(component);
    }
}
